.container__dos{
    height: 1600px;
    padding:0rem 3rem;
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include tablet{
        height: 850px;
        margin-top: 80px;
        
    }

    @include desktop{
        height: 620px;
        margin-top: 120px;
        padding: 3rem;
    }

    @include desktopGrande{
        height: 650px;
        margin-top: 120px;
        padding: 3rem;
    }
}

.titulo__estilos{

    text-align: center;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include desktop{

    }

    h2{
        flex: 100%;
        align-self: center;
        font-family: Baron;
        font-size: 2rem;
        margin-top: 3rem;

        @include desktop{
            font-size: 2.5rem;
            margin-bottom:  2rem;
            margin-top: inherit;
        }
    }

    p{
        flex: 100%;
        align-self: center;
        padding: 0 1rem;
        font-family: Roboto;
        font-size: 1rem;
        font-weight: bolder;
        text-align: justify;

        margin-top: -1rem;
        letter-spacing: 0px;

        @include tablet{
            padding: 0 0rem;
            font-family: Roboto;
            font-size: 1.2rem;
            text-align: center;
        }

        @include desktop{
            letter-spacing: 2px;
            font-size: 1rem;
            padding: 0 5rem;
            text-align: center;
            margin-top: inherit;
        }
    }
    
}

.boxes{
    justify-content: center;
    
    text-align: center;
    flex: 100%;

    display: flex;
    flex-wrap: wrap;

    @include desktop{
        margin-top: 2rem;
        height: 500px;
    }

    .titulo__box{
        flex: 100%;
    }

    .box{
        width: 200px;
        height: 200px;
        margin: 0 10px;
        text-align: center;
        overflow: hidden;
        position: relative;

        @include desktop{
            width: 210px;
            height: 210px;
            margin: 0 10px;
        }

        @include desktopGrande{
            width: 250px;
            height: 250px;
            margin: 0 10px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .hover{
            width: 0%;
            height: 250px;
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            

            h2{
                color: black;
                font-size: .5rem;
                background-color: white;

                padding: 8px 20px;
                border: 1px solid white;
                border-radius: 1rem;

                @include desktop{
                    font-size: 1rem;
                }

                
                a{
                    list-style: none;
                    text-decoration: none;
                    color: black;
                }
                 
            }
        }
    }
    
}

.box:hover .hover{
    width: 100%;  
}

.container__boxes{

    h1{
        font-family: Deluce;
        font-size: 1.5rem;
    }

    @include desktop{
        font-size: 2rem;
    }
}



