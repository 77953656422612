.container__distribuidores{
    height: 940px;
    text-align: center;
    padding-top: 2rem;

    @include tablet{
        height: 450px;
        padding-top: inherit;
    }

    @include desktop{
        height: 250px;
    }

    h2{
        font-family: Baron;
        font-weight: bolder;
        font-size: 1.8rem;
        margin-top: 2rem;
        margin-bottom: 3rem;

        @include desktop{
            margin-bottom: inherit;
            font-size: 2.3rem;
        }
    }
}

.logos{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    img{
        width: 100%;

        @include desktop{
            width: 70%;
        }

        @include desktopGrande{
            width: 90%;
        }
    }
   

    
}
.logo{
    margin: -1.6rem 1.5rem;

    @include desktop{
        margin: 1rem .1rem;
    }

    @include desktopGrande{
        margin: 1rem 1rem;
    }
}

.logop{
    margin-top: 1rem;

    @include desktop{
        margin-top: inherit;
    }
}

