.redes-sociales{
    height: 95px;
    width: 100%;
    background-color: black;

    display: flex;
    justify-content: center;
    align-items: center;
}

.logo__red{
    margin: .5rem;
}