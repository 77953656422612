.container__uno {

    width: 100%;
    height: 100vh;
}

.carousel__component {
    width: 100%;
    height:92%;

    @include desktop{
        height: 90%;
    }

    @include desktopGrande{
        height: 88%;
    }

}

.header__component {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    width: 100%;
    height: 10%;

    img {
        width: 18%;
    }


    @include desktop{

        height: 10%;
        img {
            width: 8%;
        }
    }

    @include desktopGrande {
        height: 12%;
        width: 100%;

        img {
            width: 8%;
        }
    }

}

.carousel-item1{

    background-image: url(../assets/banner_1.jpg);
    background-repeat: no-repeat;
    background-position-x: 30%;
    background-size: cover;

    min-height: 100vh;
    

    @include desktop{
        background-image: url(../assets/banner_1.jpg);
        background-position-x: inherit;
    }
}

.carousel-item2{

    background-image: url(../assets/banner_2.jpg);
    background-repeat: no-repeat;
    background-position-x: 30%;
    background-size: cover;

    height: 100vh;
    

    @include desktop{
        background-image: url(../assets/banner_2.jpg);
        background-position-x: inherit;
        
    }
}


.carousel-item3{

    background-image: url(../assets/banner_3.jpg);

    min-height: 100vh;
    background-position: center;
    background-position-x: 70%;
    background-size: cover;

    @include desktop{
        background-image: url(../assets/banner_3.jpg);
        background-position-x: inherit;
    }
}



.carousel-caption {
    position: absolute;
    display: flex;
    align-self: center;
    flex-wrap: wrap;    
    color: #fff !important;
    text-align: center;

    
    bottom: 50% !important;
    

    @include desktop{
        right: 55% !important;
        left: 15% !important;
        bottom: 45% !important;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    @include desktopGrande{

    }

    h2 {
        flex: 100%;
        font-family: Baron !important;
        font-size: 1.8rem;
        letter-spacing: .3rem;
        line-height: 2rem;
        font-weight: 300;

        @include tablet{
            font-size: 4rem;
            line-height: 3.8rem;
        }

        @include desktop{
            font-size: 2.5rem;
            line-height: 2.8rem;
            letter-spacing: .4rem;
            margin-left: 1rem;
        }

        @include desktopGrande{
            font-size: 3.5rem;
            line-height: 3.8rem;
            letter-spacing: .3rem;
        }

        span {
            letter-spacing: .4rem;
        }
    }

    p {
        margin-top: 1rem;
        flex: 100%;
        font-family: Roboto;
        font-size: 14.5px;
        text-align: justify;
        
        @include tablet{
            font-size: 1.5rem;
        }

        @include desktop{
            margin-left: .5rem;
            letter-spacing: 0.2rem;
            font-size: 1.1rem;
            text-align: justify left;
        }

        @include desktopGrande{
            margin-left: 1.5rem;
            letter-spacing: 0.1rem;
            font-size: 1.3rem;
            text-align: left;
        }
    }

    .btn {

        flex: 100%;
        text-align: center;
        display: flex;
        justify-content: center;



        a {

            color: white;
            font-family: Roboto;
            font-size: .9rem;
            padding: .2rem 2rem !important;
            border-radius: 2rem !important;
            width: 130px;
            height: 25px;
            text-decoration: none;
            margin: 0 .2rem;
            border: 1px solid white;

            &:hover {
                color: black;
                background-color: white;
            }

            @include tablet{
                font-size: 1.5rem;
                margin: 0 1rem;
            }

            @include desktop{
                margin: 0 .5rem;
                font-size: 1rem;
            }


        }
    }
}

.carousel-indicators [data-bs-target] {
    height: 0 !important;
    width: 0 !important;
}