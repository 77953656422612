.container__main{
    height: 1400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: -38rem;
    padding: 1rem 0;

    background-color: #f2f2f2;

    @include tablet{
        height: 2000px;
    }

    @include desktop{
        height: 680px;
        margin-top: inherit;
    }
    


    
}

.container__all{
    height: 285px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;

    @include tablet{
        height: 700px;
    }

    @include desktop(){
        height: 500px;
        background-color: #f2f2f2;
        
    }

    h5{
        text-align: center;
        flex: 100%;
        width: 100%;
        font-size: 3rem;
        font-family: Roboto;
        color: black;
        margin-bottom: 2rem;
        margin-top: 2rem;
        
        
       
    
        @include desktop(){
            font-size: 3rem;
            font-weight: bold;
            margin-top: inherit;
            margin-bottom: 2rem;
        }
    
        @include desktopGrande(){
            font-size: 3rem;
            font-weight: bold;
        }
    }

    
}




.container__info{
    flex: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    margin-bottom: .5rem;

    background-color: #fff;

    @include desktop(){
        flex: 23% 0 0;
    }

    p{
        text-align: center;
        font-size: 18px;
        font-family: Roboto;
    
        @include desktop(){
            font-size: 20px;
        }
    
        @include desktopGrande(){
            font-size: 21px;
        }
    }
    

    .icon{
        font-size: 1.5rem;
        color: black;
        margin-right: .5rem; 
        
        
        @include desktop(){

        }
    }


}



.container__form{
    
    
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;

    @include tablet{
        margin-top: -0em;
    }

    @include desktop{
        height: 425px;
        width: 760px;
    }

    
}



form{
    flex: 40%;
    height: 65%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    
    

    @include desktop(){
        padding: 6rem 0;
        height: 100%;
        margin-top: -20px;
    }

    input{
        width: 80%;
        border: none;
        border-bottom: 2px solid black;
        outline: none;
        margin-bottom: 1rem;
        font-size: 1.1rem;
        font-family: Roboto;
        margin-top: .5rem;
        

        @include desktop(){
            margin-bottom: 1rem;
            margin-top: .5rem;
            background-color: transparent;
        }
    }

    .wrap{
        display: flex;
        width: 80%;
        justify-content: center;
        align-items: center;
        
        input{
            flex: 3%;
            margin-top: 3rem;
            margin-left: 0.5rem;

            @include desktop(){
                margin-left: 0;
            }
        }
        p{
            flex: 90%;
            text-align: center;
            font-family: Roboto;
            font-weight: 600;
            font-size: .9rem;
            margin-bottom: -1.6rem;

            a{
                color: #F1BC83;
            }
        }
    }

    .submit{
        display: block;
        background: #F1BC83;
        
        color: white;
        cursor: pointer;
        height: 30px;
        border: none;
        border-radius: 1rem;
        outline: none;
        width: 80%;
        font-family: Roboto;
        font-weight: 500;
        font-size: 1rem;

        margin-bottom: 2rem;

        @include desktop(){
            margin-bottom: 0;
        }

    }
}

.logo__wrap{
    flex: 100%;
    img{
        width: 100%;
        height: 100%;
    }

    @include desktop{
        flex: none;
    }
}

.error{
    flex: 100% 0 0;
    display: flex;

    justify-content: center;
    align-items: center;
    height: 30px;
    margin-bottom: .5rem;

    @include desktop(){
        flex: 50%;
    }

    p{
        text-align: center;
        font-size: 16px;
        font-family: Roboto;
        font-weight: 700;
        color: black;
    }
}



//Swal

.swal2-title{
    font-family: Roboto;
    color: black;
}

.swal2-html-container{
    font-family: Roboto;
    font-size: 22px;
}

.swal2-styled.swal2-confirm{
    color: #fff;
    font-family: Roboto;
    border:none !important;
    outline: none !important;

}

.swal2-styled.swal2-confirm:before{
    color: #fff;
    font-family: Roboto;
    border:  none !important;
    outline:  none !important;
}

.swal2-styled.swal2-confirm:after{
    color: #fff;
    font-family: Roboto;
    border:  none !important;
    outline:  none !important;
}

select{
    border: none;
    font-family: Roboto;
    font-size: 1rem;
    color: black;
    margin-bottom: 3rem;
    margin-left: 1.7rem;
    margin-top: 1rem;
    width: 83%;
    background-color: transparent;
}

.swal2-styled.swal2-confirm {
    border: none;
    background-color: #F1BC83 !important;
    color: #fff;
    font-size: 1em;
}
