.container_muestras{
    width: 100%;
    height: 650px;
    display: flex;
    flex-wrap: wrap;

    @include desktop{
        height: 100vh;
    }

    .logo_muestras{
        flex: 100%;
        display: flex;
        height: 300px;
        justify-content: center;
        align-items: center;
        background-color: black;

        @include desktop{
            flex: 50%;
            height: 100%;
        }

        .logo{
            
            align-self: center;
            justify-self: center;

        @include desktop{
            margin-left: -10rem;
        }

            img{
                width: 100%;
                height: 100%;
            }

            
        }
    }
    .container_muestras_slide{
        position: relative;
        flex: 100%;
        display: flex;
        height: 400px;
        align-items: center;
        background-color: black;
        margin-top: -9rem;

        @include telefonoGrande{
            height: 400px;
            
        }

        @include desktop{
            flex: 50%;
            height: 100%;
            background-color: white;
            margin-top: inherit;
        }

        .container_slide{
            overflow: hidden;
            width: 390px;
            height: 75%;

            @include telefonoGrande{      
                width: 405px;
            }

            @include tablet{
                width: 820px;
                
            }

            @include desktop{
                position: absolute;
                right: 10%;
                width: 800px;
                height: 75%;
            }

            @include desktopGrande{
                position: absolute;
                right: 10%;
                width: 1000px;
                height: 75%;
            }

            
    

            .carousel-indicators [data-bs-target]{
                height: 0rem !important;
                width: 0rem !important;
            }

        }

        

    }
}

.img_muestra{
    width: 100%;
    height: 100%;
}


.container_muestras_slide.carousel-control-next-icon,
.container_muestras_slide.carousel-control-prev-icon {
    height: 3rem !important;
    width: 3rem !important;
}